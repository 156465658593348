import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { Container, Box, Flex, Button } from '@chakra-ui/react'
import { H2 } from '../theme/Headings'
import { spacing, breakpoints, colors, typography, lumVal } from '../theme/themeAlopex'
import ColorContext from '../context/ColorContext'

const Footer = ({ fTitle, mode, overlay }) => {
  const { color } = useContext(ColorContext)

  const {footerbg01, settings} = useStaticQuery(
    graphql`
      query {
        footerbg01: file(relativePath: { eq: "footerbg01.png" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        settings: contentJson(slug: {eq: "globalSettings"}) {
          phone
        }
      }
    `
  )
  
  // Default footer mode
  let content = <>
      <Container maxWidth={breakpoints.lg} p={[`${spacing.groups} 1rem`, null, `${spacing.section} 1rem`]} position="relative" zIndex="1">
        <H2 textAlign="center">
          {fTitle ? (
            fTitle
            ) : (
            "Text or call us!"
          )}
        </H2>
        <Box as="p" textAlign="center">
          We're happy to talk to you about projects big and small.<br />Contact the Alopex main office at:
        </Box>
        <Box
          sx={{
            'a button': {
              color: lumVal(color.alpha) < lumVal(color.bravo) ? color.alpha : color.bravo,
              transition: 'color 0.2s ease-in-out, background-color 0.2s ease-in-out',
              '&:hover': {
                color: '#ffffff',
                backgroundColor: lumVal(color.alpha) < lumVal(color.bravo) ? color.alpha : color.bravo,
              }
            }
          }}
        >
          <a href={`sms:${settings?.phone}`}>
            <Button variant="buttonFooter" style={{height: 'auto'}}>Text</Button>
          </a>
          <a href={`tel:${settings?.phone}`}>
            <Button variant="buttonFooter" style={{height: 'auto'}}>Call</Button>
          </a>
        </Box>
        <Box
          position="absolute"
          bottom="20px"
          left="50%"
          transform="translateX(-50%)"
          sx={{
            a: {
              fontSize: 'smaller',
              textDecoration: 'none'
            }
          }}
        >
          <a href="https://www.iubenda.com/privacy-policy/69654251" target="_blank" rel="noreferrer">Privacy Policy</a>
        </Box>
      </Container>
  </>
  
  // Alternate footer modes
  switch (mode) {
    case 'bg01':
      content = <>
        <BackgroundImage
          fluid={footerbg01.childImageSharp.fluid}
          backgroundColor={colors.brand.slate}
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundSize: 'cover',
            backgroundBlendMode: 'soft-light',
            zIndex: 0,
          }}
        />
        {overlay &&
          <Box
            position="absolute"
            width="100%"
            height="100%"
            opacity="0.6"
            backgroundImage={`linear-gradient(to right, ${color.alpha}, ${color.bravo})`}
          />
        }
        <Container maxWidth={breakpoints.lg} p={[`${spacing.groups} 1rem`, null, `${spacing.section} 1rem`]} position="relative" zIndex="1">
          <H2 variant="bigLikeh1" textAlign="left" mb={spacing.elements}>
            {fTitle ? (
                fTitle
              ) : (
                "Are you ready to see your project on this list?"
              )
            }
          </H2>
          <Flex
            flexWrap="wrap"
            justify="center"
            sx={{
              'a button': {
                color: lumVal(color.alpha) < lumVal(color.bravo) ? color.alpha : color.bravo,
                transition: 'color 0.2s ease-in-out, background-color 0.2s ease-in-out',
                '&:hover': {
                  color: '#ffffff',
                  backgroundColor: lumVal(color.alpha) < lumVal(color.bravo) ? color.alpha : color.bravo
                }
              }
            }}
          >
            <a href={`sms:${settings?.phone}`}>
              <Button variant="buttonFooter" style={{height: 'auto'}}>Text</Button>
            </a>
            <Box as="span" display={['none', null, 'inline-block']} padding="0 5px" fontWeight="normal" fontFamily={typography.fonts.heading} fontSize="45px">or</Box>
            <a href={`tel:${settings?.phone}`}>
              <Button variant="buttonFooter" style={{height: 'auto'}}>Call</Button>
            </a>
            <Box as="span" display={['none', null, 'inline-block']} padding="0 5px" fontFamily={typography.fonts.heading} fontSize="45px">us now.</Box>
          </Flex>
          <Box
            position="absolute"
            bottom="20px"
            left="50%"
            transform="translateX(-50%)"
            sx={{
              a: {
                fontSize: 'smaller',
                textDecoration: 'none'
              }
            }}
          >
            <a href="https://www.iubenda.com/privacy-policy/69654251" target="_blank" rel="noreferrer">Privacy Policy</a>
          </Box>
        </Container>
      </>
      break
    default:
      break
  }
  
  return (
    <Box backgroundColor={colors.brand.slate} color={colors.brand.white} textAlign="center" position="relative">
      {content}
    </Box>
  )
}

export default Footer